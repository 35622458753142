<template>
  <el-dialog v-model="openDialogVisible" title="短信配置" width="35%" center :before-close="beforeClose">
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
      <el-form-item label="商户简称：" prop="merchant_alias">
        <el-input v-model="ruleForm.merchant_alias" :disabled="true"></el-input>
      </el-form-item>
      <el-form-item label="appid" prop="sms_config_appid">
        <el-input v-model="ruleForm.sms_config_appid"></el-input>
      </el-form-item>
      <el-form-item label="用户名(username)" prop="sms_config_username">
        <el-input v-model="ruleForm.sms_config_username"></el-input>
      </el-form-item>
      <el-form-item label="密码(password)" prop="sms_config_password">
        <el-input v-model="ruleForm.sms_config_password"  type="password" show-password></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel">取消</el-button>
        <el-button type="primary" @click="handleSubmit()">确认保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus';

export default {
  props: {
    open: {
      type: Boolean,
      required: true,
    },
    merchantDetail: {
      type: Object,
    },
    reSearch: {
      type: Function,
      required: true
    }
  },
  data() {
    return {
      ruleForm: {
        sms_provider: 'xxl',
        sms_config_appid: '',
        sms_config_username: '',
        sms_config_password: '',
        merchant_id: '',
        merchant_alias: '',
      },
      openDialogVisible: this.open,
      rules: {
        sms_config_appid: [
          { required: true, message: 'appid不能为空', trigger: 'blur' },
        ],
        sms_config_username: [
          { required: true, message: 'username不能为空', trigger: 'blur' },
        ],
        sms_config_password: [
          { required: true, message: 'password不能为空', trigger: 'blur' },
        ],
      },
    };
  },
  watch: {
    open(newVal) {
      this.openDialogVisible = newVal;
      if (newVal) {
        this.loadMerchantDetails();
      }
    },
    merchantDetail: {
      handler(newVal) {
        this.ruleForm.merchant_alias = newVal.merchant_alias;
        this.ruleForm.merchant_id = newVal.id;
        this.ruleForm.sms_provider = newVal.sms_provider || 'xxl';
        this.ruleForm.sms_config_appid = newVal.sms_config?.appid || "";
        this.ruleForm.sms_config_username = newVal.sms_config?.username || "";
        this.ruleForm.sms_config_password = newVal.sms_config?.password || "";
      },
      immediate: true,
    },
  },
  methods: {
    cancel() {
      this.$emit('update:open', false);
      this.$refs.ruleForm.resetFields();
      this.openDialogVisible = false;
    },
    beforeClose() {
      this.$refs.ruleForm.resetFields();
      this.$emit('update:open', false);
    },
    async handleSubmit() {
      try {
        const valid = await new Promise((resolve) => {
          this.$refs.ruleForm.validate((valid) => {
            resolve(valid);
          });
        });

        if (!valid) {
          ElMessage({
            message: '请先填写完整',
            type: 'error',
          });
          return;
        }

        let r;
        r = await this.$api.merchant.updateSmsConfig(this.ruleForm.merchant_id, this.ruleForm);
        if (r.status == 200) {
          ElMessage({
            message: '保存成功',
            type: 'success',
          });
          this.reSearch();
          this.openDialogVisible = false;
          this.$emit('update:open', false);
        }
      } catch (error) {
        ElMessage({
          message: '发生错误，请稍后重试',
          type: 'error',
        });
      }
    },
    loadMerchantDetails() {
      this.ruleForm.merchant_alias = this.merchantDetail.merchant_alias;
      this.ruleForm.merchant_id = this.merchantDetail.id;
      this.ruleForm.sms_provider = this.merchantDetail.sms_provider || 'xxl';
      this.ruleForm.sms_config_appid = this.merchantDetail.sms_config?.appid || "";
      this.ruleForm.sms_config_username = this.merchantDetail.sms_config?.username || "";
      this.ruleForm.sms_config_password = this.merchantDetail.sms_config?.password || "";
    },
  },
};
</script>
