<template>
    <el-dialog v-model="isShowList" width="1200px" :draggable="true" :destroy-on-close="true">
        <template #header> 商户名称 : {{ merchantInfo.merchant_name }} </template>
        <div class="table-head">
            <span class="table-title">商户门店列表</span>
            <span class="table-btn">
                <el-button @click="addStore" type="primary">
                    <el-icon style="color: #fff">
                        <Plus />
                    </el-icon>
                    <span style="margin-left: 15px">添加门店</span>
                </el-button>
            </span>
        </div>
        <div class="table-main">
            <c-table ref="storeTableRef" :border="true" :tableDataUrl="tableDataUrl" :isLoadData="isLoadStoreTableData">
                <el-table-column type="index" width="55" label="序号" :align="'center'" />
                <el-table-column prop="name" label="门店名称" />
                <el-table-column prop="address" label="门店地址" />
                <el-table-column prop="contact_name" label="联系人" />
                <el-table-column prop="phone" label="联系电话" />
                <el-table-column label="操作">
                    <template #default="scope">
                        <el-link style="margin-left: 15px" type="primary" @click="edit(scope.row)">编辑</el-link>
                        <el-link style="margin-left: 15px" type="primary" @click="openPlugin(scope.row)">插件开通</el-link>
                    </template>
                </el-table-column>
            </c-table>
        </div>
    </el-dialog>
    <el-dialog v-model="isShowAddStore" width="800px" :draggable="true" :destroy-on-close="true">
        <template #header> 添加门店 </template>
        <el-form ref="storeFormRef" :model="storeForm" :rules="rules" label-width="150px">
            <el-row>
                <el-col :span="20">
                    <el-form-item label="门店名称" prop="name">
                        <el-input v-model="storeForm.name" :value="storeForm.name" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="门店地址" prop="address">
                        <el-input v-model="storeForm.address" :value="storeForm.address" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="联系人" prop="contact_name">
                        <el-input v-model="storeForm.contact_name" :value="storeForm.contact_name" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="联系电话" prop="phone">
                        <el-input v-model="storeForm.phone" :value="storeForm.phone" maxlength="11" clearable />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="状态" prop="status">
                        <el-radio-group v-model="storeForm.status">
                            <el-radio :label="1">启用</el-radio>
                            <el-radio :label="0">禁用</el-radio>
                        </el-radio-group>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <el-button @click="cancel">取消</el-button>
            <el-button type="primary" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="isShowCashRegisterList" width="800px" :draggable="true" :destroy-on-close="true">
        <template #header>
            <el-button @click="addCashierPlugin" type="primary">
                <el-icon style="color: #fff">
                    <Plus />
                </el-icon>
                <span style="margin-left: 15px">添加插件激活码</span>
            </el-button>
        </template>
        <c-table ref="cashRegisterTableListRef" :tableDataUrl="cashRegisterTableDataUrl" :isLoadData="false">
            <el-table-column type="index" width="55" label="序号" :align="'center'" />
            <el-table-column prop="secret_key" label="设备激活码" />
            <el-table-column prop="cashier_name" label="收银员" />
            <el-table-column label="创建日期">
                <template #default="scope">
                    <span>{{ timeFormat(scope.row.created_at) }}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作">
                <template #default="scope">
                    <el-link type="primary" @click="showQrCode(scope.row)">聚合码</el-link>
                </template>
            </el-table-column>
        </c-table>
    </el-dialog>
    <el-dialog v-model="isShowAddCashRegister" width="800px" :draggable="true" :destroy-on-close="true">
        <template #header> 添加收银插件 </template>
        <el-form ref="cashierPluginFormRef" :model="cashierPluginForm" :rules="rules" label-width="150px">
            <el-row>
                <el-col :span="20">
                    <el-form-item label="门店名称">
                        <el-input :value="storeInfo.name" readonly />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row>
                <el-col :span="20">
                    <el-form-item label="收银员" :rules="[{ required: true, message: '必填' }]">
                        <el-select v-model="cashierPluginForm.cashier_id" class="m-2" placeholder="Select" size="large">
                            <el-option v-for="cashierCode in cashierCodeList" :key="cashierCode.id"
                                :label="cashierCode.name" :value="cashierCode.id" />
                        </el-select>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <template #footer>
            <el-button @click="cancelCashier">取消</el-button>
            <el-button type="primary" @click="submitCashier">确定</el-button>
        </template>
    </el-dialog>
    <el-dialog v-model="isShowImage" width="500px" :title="qrCodeTitle" center>
        <div style="text-align: center">
            <qrcode-vue :value="qrCodeUrl" :size="250" />
        </div>
        <template #footer>
            <el-button @click="copyUrl">复制链接</el-button>
        </template>
    </el-dialog>
</template>

<script setup>
import { ref, reactive, defineExpose, getCurrentInstance } from "vue";
import { ElMessage } from 'element-plus'
import { Plus } from "@element-plus/icons-vue";
import cTable from "@/components/CustomTable";
import { nextTick } from "process";
import { timeFormat } from "@/func/time";
import { phone } from "@/func/validatorFunc";
import QrcodeVue from "qrcode.vue";
const { proxy } = getCurrentInstance(); // 当前实例

//ref
const storeTableRef = ref(null);
const storeFormRef = ref(null);
const cashRegisterTableListRef = ref(null);
const cashierPluginFormRef = ref(null);

const tableDataUrl = proxy.$api.merchant.store.list;
const cashRegisterTableDataUrl = proxy.$api.merchant.store.cashRegisterList;

const merchantInfo = ref("");
const storeInfo = ref("");
const qrCodeTitle = ref("");
const qrCodeUrl = ref("");

const isLoadStoreTableData = ref(false);
const isShowList = ref(false);
const isShowAddStore = ref(false);
const isShowCashRegisterList = ref(false);
const isShowAddCashRegister = ref(false);
const isSotreEdit = ref(false);
const isShowImage = ref(false);

const storeForm = ref({
    merchant_id: 0,
    name: "",
    address: "",
    contact_name: "",
    phone: "",
    status: 1,
    id: "",
})

const rules = reactive({
    name: [{ required: true, message: "必填" }],
    address: [{ required: true, message: "必填" }],
    contact_name: [{ required: true, message: "必填" }],
    phone: [
        { required: true, message: "必填" },
        { validator: phone, trigger: "change" },
    ],
})

const cashierPluginForm = ref({
    cashier_id: "",
});

const cashierCodeList = ref([]);
/**门店开始 */
const addStore = () => {
    isShowAddStore.value = true;
    storeForm.value.merchant_id = merchantInfo.value.id;
};

const cancel = () => {
    isShowAddStore.value = false;
};

const submit = () => {
    proxy.$refs.storeFormRef.validate((validate) => {
        if (validate) {
            if (isSotreEdit.value) {
                proxy.$api.merchant.store
                    .edit(storeForm.value.id, storeForm.value)
                    .then((r) => {
                        refreshStoreTable(r);
                    });
            } else {
                proxy.$api.merchant.store.add(storeForm.value).then((r) => {
                    refreshStoreTable(r);
                });
            }
        }
    });
};

const refreshStoreTable = (data) => {
    if (data.status == 200) {
        nextTick(() => {
            storeTableRef.value.refresh();
        });

        storeForm.value = {};
        isShowAddStore.value = false;
    }
};

const edit = (item) => {
    storeForm.value = item;
    isShowAddStore.value = true;
    isSotreEdit.value = true;
};
/**门店结束 */

/* 插件开始 */
const openPlugin = (item) => {
    const params = {
        store_id: item.id,
    };

    nextTick(() => {
        cashRegisterTableListRef.value.setCustomLoad(true);
        cashRegisterTableListRef.value.setParams(params);
    });
    storeInfo.value = item;
    isShowCashRegisterList.value = true;
};

const addCashierPlugin = () => {
    isShowAddCashRegister.value = true;
    proxy.$api.merchant.store
        .cashierCodeList({ store_id: storeInfo.value.id })
        .then((r) => {
            cashierCodeList.value = r.data;
        });
};

const cancelCashier = () => {
    isShowAddCashRegister.value = false;
    storeInfo.value = "";
};

const submitCashier = () => {
    proxy.$refs.cashierPluginFormRef.validate((validate) => {
        if (validate) {
            cashierPluginForm.value.store_id = storeInfo.value.id;
            proxy.$api.merchant.store
                .addCashierCode(cashierPluginForm.value)
                .then((r) => {
                    if (r.status == 200) {
                        cancelCashier();
                        nextTick(() => {
                            cashRegisterTableListRef.value.refresh();
                        });
                    }
                });
        }
    });
};

const showQrCode = (cashierInfo) => {
    isShowImage.value = true;
    qrCodeTitle.value = cashierInfo.store_name + "- 聚合支付二维码";
    qrCodeUrl.value = proxy.$config.http + "api/pay/polymerization/?store_id=" + storeInfo.value.id + "&merchant_id=" + storeInfo.value.merchant_id + "&agent_id=" + storeInfo.value.agent_id + '&cashier_id=' + cashierInfo.id
};

const copyUrl = () => {
    let tag = document.createElement('textarea');
    tag.setAttribute('id', 'cp_hgz_input');
    tag.value = qrCodeUrl.value;
    document.getElementsByTagName('body')[0].appendChild(tag);
    document.getElementById('cp_hgz_input').select();
    document.execCommand('copy');
    document.getElementById('cp_hgz_input').remove();
    ElMessage({ message: '复制成功', type: 'success' })
}

/* 插件结束 */

const open = (item) => {
    isShowList.value = true;
    merchantInfo.value = item;

    nextTick(() => {
        storeTableRef.value.setCustomLoad(true);
        storeTableRef.value.setParams({ merchant_id: item.id });
    });
};

defineExpose({ open });
</script>
