<template>
  <div class="form-box">
    <el-card shadow="never">
      <el-form :model="form" label-width="120px" label-position="right" inline>
        <el-form-item label="商户简称:">
          <el-select v-model="form.merchant_id" placeholder="请选择" filterable clearable style="width: 180px;" allow-create>
            <el-option v-for="option in optionsMerchant" :key="option.id"
                       :label="option.no + '-' + option.merchant_alias" :value="option.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商户全称:">
          <el-select v-model="form.merchant_id" placeholder="请选择" filterable clearable style="width: 180px;" allow-create>
            <el-option v-for="option in optionsMerchant" :key="option.id"
                       :label="option.no + '-' + option.merchant_name" :value="option.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="联系人:" class="form-item">
          <el-input v-model="form.contact_person_name" placeholder="请输入联系人"/>
        </el-form-item>
        <el-form-item label="手机号:" class="form-item">
          <el-input v-model="form.contact_person_phone" placeholder="请输入手机号"/>
        </el-form-item>
        <el-form-item label="代理商:" class="form-item">
          <el-select v-model="form.agent_id" filterable clearable>
            <el-option v-for="(agent, index) in agents" :key="index" :label="agent.company"
                       :value="agent.id"/>
          </el-select>
        </el-form-item>
        <el-form-item label="业务员:" class="form-item">
          <el-select v-model="form.salesman_id" filterable clearable>
            <el-option v-for="(salesman, index) in salesmanList" :key="index" :label="salesman.name"
                       :value="salesman.id"/>
          </el-select>
        </el-form-item>
        <div style="display: flex;justify-content: center;">
          <el-button type="primary" id="search" @click="search">
            查询
          </el-button>
          <el-button plain @click="resetSearch">
            重置
          </el-button>
          <el-button v-if="!showMultiChangeSelection" type="warning" plain  @click="showSelection(true)">批量操作</el-button>
          <el-button v-if="showMultiChangeSelection" type="info" plain
                     @click="showSelection(false)">取消批量操作
          </el-button>
          <el-button v-if="showMultiChangeSelection" type="primary" plain style="margin-left:10px"
                     @click="showMerchantChangeBox">转移商户
          </el-button>
        </div>
      </el-form>
    </el-card>
  </div>
  <div class="table-box">
    <div class="table-main">
      <c-table ref="table" :tableDataUrl="tableDataUrl" :border="true" :defaultSort="defaultSort"
               :isLoadData="true" @selectionRows="testSelect" :stripe="true" :is-simple-pagination="false">
        <template v-if="showMultiChangeSelection">
          <el-table-column type="selection"></el-table-column>
        </template>
        <el-table-column prop="no" label="商户号" min-width="100"/>
        <el-table-column prop="merchant_name" label="商户名称" show-overflow-tooltip min-width="260">
          <template #default="scope">
            <div @click="handleCopy(scope.row.merchant_name)" style="display: flex;gap: 2px;">
              <el-button plain :icon="CopyDocument" size="small" circle></el-button>
              <div>
                {{ scope.row.merchant_name }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="merchant_alias" label="商户简称" show-overflow-tooltip min-width="240">
          <template #default="scope">
            <div @click="handleCopy(scope.row.merchant_alias)" style="display: flex;gap: 2px;">
              <el-button plain :icon="CopyDocument" size="small" circle></el-button>
              <div>
                {{ scope.row.merchant_alias }}
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="联系人信息" show-overflow-tooltip min-width="180">
          <template #default="scope">
            <span>{{ scope.row.contact_person_name }} - {{ scope.row.contact_person_phone }}</span>
          </template>
        </el-table-column>
        <el-table-column label="商户类型" min-width="120" show-overflow-tooltip>
          <template #default="scope">
            <span v-if="scope.row.business_type == 0">企业</span>
            <span v-if="scope.row.business_type == 1">个体工商户</span>
            <span v-if="scope.row.business_type == 2">小微商户</span>
            <span v-if="scope.row.business_type == 3">企业商户</span>
          </template>
        </el-table-column>
        <el-table-column label="业务员" min-width="80" show-overflow-tooltip>
          <template #default="scope">
            {{ scope.row.salesman.name }}
          </template>
        </el-table-column>
        <el-table-column label="二级代理商" show-overflow-tooltip min-width="100">
          <template #default="scope">
            {{ scope.row.agent.second_agent_name }}
          </template>
        </el-table-column>
        <el-table-column label="三级代理商" show-overflow-tooltip min-width="100">
          <template #default="scope">
            {{ scope.row.agent.three_agent_name }}
          </template>
        </el-table-column>
        <el-table-column prop="created_at" label="创建时间" show-overflow-tooltip min-width="120">
          <template #default="scope">
            {{ format(scope.row.created_at) }}
          </template>
        </el-table-column>
        <el-table-column label="操作" #default="scope" fixed="right" width="240">
          <div class="operate operate-icon">
            <el-button type="primary" @click="edit(scope.row)" :icon="Edit" circle size="small">
            </el-button>
            <el-button type="success" @click="merchantChannel(scope.row)" :icon="Switch" circle
                       size="small">
            </el-button>
            <el-button type="warning" @click="merchantStore(scope.row)" :icon="Shop" circle size="small">
            </el-button>
            <el-dropdown style="margin-left: 5px;">
                            <span class="el-dropdown-link">
                                <el-button :icon="Operation" size="small">
                                    更多
                                </el-button>
                            </span>
              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item @click="merchantChannel(scope.row)">商户通道</el-dropdown-item>
                  <el-dropdown-item @click="merchantStore(scope.row)">门店管理</el-dropdown-item>
                  <el-dropdown-item @click="merchantCashier(scope.row)">收银员管理</el-dropdown-item>
                  <el-dropdown-item @click="merchantDevice(scope.row)">设备管理</el-dropdown-item>
                  <el-dropdown-item @click="handleUpdateSmsConfig(scope.row)">短信配置</el-dropdown-item>
                  <el-dropdown-item @click="merchantActivity(scope.row)">活动管理</el-dropdown-item>
                  <el-dropdown-item @click="showMerchantChangeBox(scope.row)">商户转移</el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>
        </el-table-column>
      </c-table>
    </div>
  </div>
  <el-dialog v-model="isShowChangeBox">
    <template #header>
      商户迁移
    </template>
    <el-form :model="changeForm" label-width="120px">
      <el-form-item label="代理商：">
        <el-select v-model="changeForm.agent_id" filterable clearable @change="selectorSalesman">
          <el-option v-for="(agent, index) in agents" :key="index" :label="agent.company" :value="agent.id"/>
        </el-select>
      </el-form-item>
      <el-form-item label="业务员：">
        <el-select v-model="changeForm.salesman_id" filterable clearable>
          <el-option v-for="(salesman, index) in agentLevelSalesmanList" :key="index" :label="salesman.name"
                     :value="salesman.id"/>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="merchantChange">确定</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
  <merchant-channel ref="merchant_channel"></merchant-channel>
  <merchant-store ref="merchant_store"></merchant-store>
  <cashier ref="merchant_cashier"></cashier>
  <device ref="merchant_device"></device>
  <activity ref="merchant_activity"></activity>
  <SmsConfigDialog :open="smsDialogVisible" @update:open="handleUpdateSmsOpen" :merchantDetail="merchantMes"
                   :reSearch="search"/>
</template>

<script>
import cTable from '@/components/CustomTable'
import {timeFormat} from '@/func/time'
import {CopyDocument, Edit, Operation, Search, Shop, Switch} from '@element-plus/icons-vue'
import storeTools from '@/tools/storeTools'
import merchantChannel from './channel'
import merchantStore from './store'
import cashier from './cashier'
import device from './device'
import activity from './activity'
import {ElMessage} from 'element-plus'
import SmsConfigDialog from "@/components/SmsConfigDialog/index.vue";

export default {
  computed: {
    CopyDocument() {
      return CopyDocument
    },
    Switch() {
      return Switch
    },
    Shop() {
      return Shop
    },
    Operation() {
      return Operation
    },
    Edit() {
      return Edit
    },
    Search() {
      return Search
    }
  },
  components: {
    cTable,
    merchantChannel,
    merchantStore,
    cashier,
    device,
    activity,
    SmsConfigDialog
  },
  mounted() {
    this.loadAgents()
    this.loadSalesmanList()
    this.getOptionsMerchant()
  },
  data() {
    return {
      defaultSort: {
        prop: 'sort', order: 'descending'
      },
      tableDataUrl: this.$api.merchant.list,
      form: {},
      optionsMerchant: [],
      changeForm: {},
      merNo: '',
      agents: null,
      salesmanList: null,
      isShowChangeBox: false,
      agentLevelSalesmanList: [],
      merchant: null,
      showMultiChangeSelection: false,
      merchantIds: [],
      smsDialogVisible: false,
      merchantMes: {}
    }
  },
  methods: {
    getOptionsMerchant(data) {
      this.$api.dict.getMerchantDict(data).then(res => {
        if (res.status == 200) {
          this.optionsMerchant = res.data.data
        }
      })
    },
    handleCopy(data) {
      ElMessage({
        message: '复制成功',
        type: 'success',
      });
      let tempcopy = document.createElement("input");
      tempcopy.value = data;
      document.body.appendChild(tempcopy);
      tempcopy.select();
      document.execCommand('Copy');
      document.body.removeChild(tempcopy); // 复制完成后，移除临时输入框
    },

    search() {
      this.$nextTick(() => {
        this.$refs.table.search(this.form)
      })
    },
    resetSearch() {
      this.form = {}
      this.$nextTick(() => {
        this.$refs.table.search(this.form)
      })
    },
    format(date) {
      return timeFormat(date)
    },

    edit(merchantInfo) {
      storeTools.setTabs('新增商户', 'merchant_new', '/merchant/new')
      this.$router.push({path: '/merchant/new', query: {id: merchantInfo.id}})
    },
    merchantChannel(merchantInfo) {
      this.$nextTick(() => {
        this.$refs.merchant_channel.open(merchantInfo)
      })

    },
    merchantStore(merchantInfo) {
      this.$nextTick(() => {
        this.$refs.merchant_store.open(merchantInfo)
      })
    },
    merchantCashier(merchantInfo) {
      this.$nextTick(() => {
        this.$refs.merchant_cashier.open(merchantInfo)
      })
    },
    merchantDevice(merchantInfo) {
      this.$nextTick(() => {
        this.$refs.merchant_device.open(merchantInfo)
      })
    },
    merchantActivity(merchantInfo) {
      this.$nextTick(() => {
        this.$refs.merchant_activity.open(merchantInfo)
      })
    },
    showMerchantChangeBox(merchantInfo) {
      this.isShowChangeBox = true
      this.merchant = merchantInfo
    },
    selectorSalesman(val) {
      const params = {
        agent_id: val
      }
      this.$api.salesman.levelAgentSalesman(params).then(r => {
        this.agentLevelSalesmanList = r.data
      })
    },
    merchantChange() {
      if (!this.changeForm.agent_id || !this.changeForm.salesman_id) {
        ElMessage({message: '参数有误', type: 'error'})
        return
      }
      if (this.merchant.id) {
        this.merchantIds = [this.merchant.id]
      }
      const params = {
        merchant_id: this.merchantIds,
        agent_id: this.changeForm.agent_id,
        salesman_id: this.changeForm.salesman_id
      }

      this.$api.merchant.merchantChange(params).then(r => {
        if (r.status == 200) {
          ElMessage({message: '修改成功', type: 'success'})
          this.isShowChangeBox = false
        }
      })
    },
    uploadOrder() {
      console.log(this.merNo)
    },
    loadAgents() {
      this.$api.agent.levelAgents.list().then(r => {
        this.agents = r.data
      })
    },
    loadSalesmanList() {
      this.$api.salesman.levelAgentSalesman().then(r => {
        this.salesmanList = r.data
      })
    },
    showSelection(isShow) {
      this.showMultiChangeSelection = isShow
    },
    testSelect(rows) {
      this.merchantIds = []

      for (var i = 0; i < rows.length; i++) {
        this.merchantIds.push(rows[i].id)
      }
    },
    handleUpdateSmsConfig(row) {
      this.smsDialogVisible = true
      this.merchantMes = row
    },
    handleUpdateSmsOpen(newValue) {
      this.smsDialogVisible = newValue;
    }


  }
}
</script>

<style lang="less" scoped>
.operate {
  color: #409EFF;
  cursor: pointer;
  margin-left: 10px;
}

.form-box {
  margin-bottom: 10px;

  .form-item {
    width: 300px;
    /* 设定固定宽度 */
  }


  .search-btn {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
